.cont_left,
.cont_right {
  width: 500px;
}

.cont_left {
  margin: 10px;
}

/* 뉴스 요약 */
.news_wrap .scroll {
  height: 356px;
}

.news_card_wrap {
  padding: 10px;
  margin-bottom: 15px;
  margin-right: 15px;
  border-radius: 5px;
}

.cont_box .label1 {
  color: var(--secondary);
  padding-bottom: 5px;
}

.news_card_wrap .d-between {
  align-items: flex-start;
  cursor: pointer;
}

.news_card_wrap .news_thum {
  width: 296px;
}

.news_card_wrap .news_thum > .d-between {
  padding-bottom: 6px;
}

.news_card_wrap .news_thum .d-flex .label3 {
  padding-left: 3px;
}

.news_logo_icon {
  height: 20px;
  border-radius: 50%;
}

.rating {
  color: var(--orange);
  letter-spacing: -1px;
  font-size: 12px;
}

.news_img {
  width: 120px;
  height: 80px;
}
.news_img img {
  width: 100%;
  height: 80px;
  object-fit: contain;
  border-radius: 8%;
}

.news_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news_date {
  color: var(--gray3);
  padding: 5px 0;
}

.news_nodata {
  height: 336px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.news_nodata p {
  color: var(--secondary);
  text-align: center;
}

.news_nodata p.label3 {
  padding: 25px 0 10px;
}

/* 관련뉴스 보기 */
.news_related_button {
  position: relative;
}

.news_related_button .news_related {
  max-height: 310px;
}

.news_open {
  color: var(--primary);
}

.news_related {
  overflow-y: scroll;
}

.news_related .body4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 8px;
  padding-top: 8px;
}

.news_related .body3 {
  overflow: hidden;
  width: 400px;
  margin-top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 8px;
}

/* 뉴스 - 긍정 부정 */
.news_icon {
  background-repeat: no-repeat;
  background-size: 16px;
  height: 16px;
  font-size: 11px;
  font-weight: 700;
  padding-left: 17px;
  margin-right: 1px;
  display: flex;
  align-items: center;
}
.news_icon_yes {
  background-image: url(../assets/good.png);
  color: var(--pink);
}

.news_icon_no {
  background-image: url(../assets/no.png);
  color: #52a3c4;
}

.news_icon_same {
  background-image: url(../assets/same.png);
  color: #2ea591;
}
