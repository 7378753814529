.reports_wrap table {
  text-align: left;
}

.reports_wrap table thead {
  height: 35px;
  font-weight: 600;
}

.reports_wrap table thead tr {
  display: block;
  padding: 0 32px;
}

.reports_wrap table thead tr th {
  width: 385px;
  height: 35px;
  padding: 10px 14px;
}

.reports_wrap table thead tr th:nth-child(1),
.reports_wrap table thead tr th:nth-child(3) {
  width: 200px;
}

.reports_wrap table thead tr th:nth-child(2) {
  width: 530px;
}

tbody.scroll {
  display: block;
  width: 988px;
  height: 315px;
  margin: 0 10px 0 0;
  padding: 0 22px 0 32px;
}

.reports_wrap table tbody tr {
  cursor: pointer;
}

.reports_wrap table tbody tr td:nth-child(1),
.reports_wrap table tbody tr td:nth-child(3) {
  width: 200px;
}

.reports_wrap table tbody tr td:nth-child(2) {
  width: 538px;
}

.reports_wrap table tbody tr td {
  padding: 15px 13px;
}

.reports_wrap .d-end {
  padding: 10px 20px;
}
