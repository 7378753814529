/* 금융 및 시장 현황, 주식 현황 */
.market_wrap .cont_right {
  padding: 5px 20px 0;
}

.market_cont div {
  padding: 15px 0 40px;
}

.market_cont .market_rate {
  margin-top: 10px;
}

.market_cont h1 {
  padding: 2px 0;
}

.market_cont .d-end {
  padding-top: 10px;
}

/* 주식 현황 */
.cont_box .market_cont div {
  padding: 0;
}

.cont_box .cont1 {
  padding: 15px 0 10px !important;
}

.cont_box .cont3 {
  width: 100%;
  padding: 5px 0 !important;
}

.cont_box .border-right {
  padding-right: 8px !important;
  margin-right: 8px;
}
