.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--gray5);
  margin: 0 2px;
}

ul.pagination li:hover {
  transition: all 0.5s;
  background-color: var(--gray5);
}

ul.pagination li a {
  text-decoration: none;
  color: var(--black);
  font-size: 14px;
}

ul.pagination li.active a {
  color: #fff;
}

ul.pagination li.active {
  background: var(--primary);
  border-radius: 5px;
  border: none;
}
