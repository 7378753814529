.setting_page_wrap {
  position: relative;
  width: 1000px;
  margin: 50px auto 15%;
  border-radius: 8px;
}

.setting_container {
  margin-left: 224px;
}

.setting_page_wrap .setting_box {
  padding: 50px;
}

.setting_btn {
  width: 170px;
  height: 45px;
  padding: 8px 18px;
  border-radius: 5px;
  background: var(--secondary);
  color: #fff;
  margin: 80px 10px 0px 0px !important;
}

.Ai_recommend .setting_btn {
  margin: 0px 0px 5px 10px !important;
  height: 30px;
  width: 120px;
  line-height: 15px;
}

/* SideNav */
.side_nav_wrap {
  position: fixed;
  top: 50px;
  width: 224px;
  height: 100vh;
  padding: 30px 32px;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 6px 4px 6.5px 0px rgba(0, 0, 0, 0.05);
  z-index: 9999;
}

.side_nav_container {
  width: 156px;
}

.side_nav_wrap .d-flex {
  padding: 10px 0 10px;
  cursor: pointer;
}

.side_nav_wrap .nav01 {
  position: relative;
  margin-bottom: 74px;
}

.side_nav_wrap .nav02 {
  position: absolute;
  top: 70px;
  left: 32px;
}

.side_nav_wrap .d-flex:before {
  content: url('../assets/dot.png');
  height: 16px;
  margin-top: -5px;
  filter: invert(1);
}

.side_nav_wrap .d-flex.active:before {
  content: url('../assets/dot_on.png');
  height: 16px;
  margin-top: -5px;
  filter: invert(1);
}

.side_nav_wrap .d-flex::after {
  content: url('../assets/arrow_right.png');
  height: 16px;
  filter: invert(1);
}

.side_nav_wrap .d-flex.active:after {
  content: url('../assets/arrow_right_on.png');
  height: 16px;
}

.side_nav_wrap .d-flex.active,
.side_nav_wrap .d-flex.active p,
.side_nav_wrap .d-flex.active::before {
  color: var(--primary);
}

.side_nav_wrap .d-flex.active::before,
.side_nav_wrap .d-flex.active::after {
  filter: invert(0);
}

.side_nav_wrap h1 {
  padding-bottom: 18px;
}

.side_nav_wrap p {
  padding: 0px 45px 0px 10px;
}

/* 사용자 관리 */
.userManage_wrap .d-start .d-flex {
  margin-right: 20px;
}

.userManage_wrap .label1 {
  margin-right: 10px;
  margin-bottom: 0 !important;
}

.userManage_wrap input {
  width: 200px !important;
}

.userManage_wrap .setting_btn {
  height: 40px;
  margin: 0 !important;
}

.userManage_wrap .btn {
  width: max-content !important;
  padding: 10px 15px !important;
}

.userManage_wrap .pass_btn {
  border: 1px solid var(--primary) !important;
  background: #fff !important;
  color: var(--primary) !important;
  margin-left: 0 !important;
}

.userManage_wrap .checkbox {
  width: auto !important;
  height: auto !important;
  padding: 0;
  margin: 0;
}

.userAdd_wrap {
  margin: 40px;
  padding: 30px 60px;
  border: 1px solid var(--gray5);
  border-radius: 8px;
}

.userAdd_wrap .form_box input {
  width: 360px !important;
}

.userManage_wrap table {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: var(--black);
}

.userManage_wrap table thead th:nth-child(1),
.userManage_wrap table tbody td:nth-child(1) {
  width: 54px;
}

.userManage_wrap table thead th:nth-child(2),
.userManage_wrap table tbody td:nth-child(2) {
  width: 100px;
}

.userManage_wrap table thead th:nth-child(3),
.userManage_wrap table tbody td:nth-child(3) {
  width: 150px;
}

.userManage_wrap table thead th:nth-child(4),
.userManage_wrap table tbody td:nth-child(4) {
  width: 150px;
}

.userManage_wrap table thead th:nth-child(5),
.userManage_wrap table tbody td:nth-child(5) {
  text-align: left;
}

.userManage_wrap table thead th:nth-child(6),
.userManage_wrap table tbody td:nth-child(6) {
  width: 200px;
}

.userManage_wrap table thead th {
  padding: 10px 20px;
  border-top: 1px solid var(--gray5);
  border-bottom: 1px solid var(--gray5);
  background: var(--BG);
  color: var(--gray2);
}

.userManage_wrap table tbody td {
  padding: 10px 20px;
  border-bottom: 1px solid var(--gray5);
}

.paging {
  margin: 50px 0;
}

/* 뉴스 검색, 주식 종목, 법규 검색 */
.secondary {
  color: var(--secondary);
}

.setting_page_wrap .heading1 {
  font-weight: 600;
  margin-bottom: 6px;
}

.setting_page_wrap .label1 {
  margin-bottom: 14px;
}

.setting_page_wrap .keyword_box {
  margin-top: 32px;
}

.keyword_box .point {
  color: var(--red);
}

.setting_page_wrap input {
  width: 550px;
  height: 40px;
  padding: 6px 12px;
  border-radius: 5px;
}

.setting_page_wrap .textarea {
  width: 560px;
  height: 150px;
  padding: 12px;
  border-radius: 5px;
  resize: none;
}

.setting_page_wrap textarea:focus-visible {
  outline: none;
}

.setting_page_wrap .btn {
  width: 80px;
  height: 40px;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin: 0 0 0 15px;
}

.setting_page_wrap .remove.btn {
  width: 80px;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  background: var(--red);
  color: #fff;
  border: none;
  margin: 0 0 0 15px;
}

.setting_page_wrap .keywords {
  color: var(--gray3);
  font-size: 14px;
  letter-spacing: -0.14px;
  display: inline-block;
  width: 560px;
  padding-bottom: 10px;
  margin-top: 15px;
}

.setting_page_wrap .keywords span {
  display: inline-block;
  color: var(--gray3);
  margin-right: 22px;
  background-image: url('../assets/remove.png');
  background-size: 15px 15px;
  background-position: 100% 4px;
  background-repeat: no-repeat;
  line-height: 22px;
}

.setting_page_wrap span .remove {
  width: 16px;
  height: 16px;
  margin: 0px 0px 0px 6px;
}

.setting_page_wrap .keyword_box {
  position: relative;
}

/* 키워드박스 > input */
.keyword_box ul {
  position: absolute;
  width: 550px;
  height: 105px;
  overflow-y: scroll;
  border: 1px solid var(--gray3);
  border-radius: 0px 0px 5px 5px;
  box-shadow: none;
}

.keyword_box ul li {
  cursor: pointer;
  padding: 5px 10px;
  vertical-align: middle;
}

.keyword_box ul li:hover {
  background-color: var(--gray5) !important;
  color: var(--secondary) !important;
}

.news_setting_keywords .keywords {
  width: 100%;
  height: 20px;
  padding-top: 0;
  border-top: 0;
  margin-top: 0;
}

.news_setting_keywords .keywords span {
  display: flex;
  justify-content: space-between;
  margin-right: 0;
  background: none;
  line-height: 14px;
}

.news_setting_keywords .keywords .remove {
  display: inline-block;
  background-image: url('../assets/remove.png');
  background-size: 15px 15px;
  background-position: 100% 0px;
  background-repeat: no-repeat;
}

.keywords_drop_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keywords_drop_wrap > .d-between {
  width: 400px;
}

/* 주식 종목 */
.search_wrap .keyword_box ul {
  height: 125px;
}

.search_wrap .input_search {
  font-size: 14px;
  padding-left: 35px;
  background-image: url(../assets/search.png) !important;
  background-repeat: no-repeat !important;
  background-position: 10px 10px !important;
}

.keyword_box ul > li {
  font-size: 14px;
  padding: 6px 20px 6px 38px;
  transition: background-color 0.2s;
  background-image: url(../assets/search.png);
  background-repeat: no-repeat;
  background-position: 10px 6px;
}

/* scroll */
.keyword_box ul::-webkit-scrollbar {
  width: 8px;
}

.keyword_box ul::-webkit-scrollbar-thumb {
  background: var(--gray4);
  border-radius: 4px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.keyword_box ul::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 4px;
}

/* 기본 정보 - 개인 정보 */
.information_wrap {
  padding: 80px 50px 80px 60px;
}

.information_wrap .keyword_box {
  margin-top: 40px;
}

.information_wrap input {
  width: 410px;
}

.pass_box #passwordInput {
  position: relative;
}

.pass_box button.btn_off {
  position: absolute;
}

.pass_box button.btn_off img {
  position: absolute;
  left: 375px;
  top: -10px;
  padding: 2px;
}

.pass_box button.btn {
  width: 136px;
  margin: 0 0 0 15px;
}

.privacy {
  position: absolute;
  top: 70px;
}

.password {
  position: absolute;
  top: 110px;
}

.information_wrap .emailCheck {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-left: 0px;
  cursor: pointer;
}

/* 금융 지수, 투자 정보 */
.setting_page_wrap .btn_item {
  padding: 6px 15px;
  border-radius: 20px;
  margin: 0 10px 12px 0;
}

/* popup */
.popup_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vw;
  background: rgb(0 0 0 / 68%);
  z-index: 999999;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  background: #fff;
  text-align: center;
}

.popup ul {
  padding: 40px 20px 30px;
  color: var(--black);
}

.popup ul li.title2 {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 20px;
}

.popup ul li {
  margin: 5px 0;
}

.popup .close {
  width: 100%;
  font-size: 16px;
  height: 50px;
  color: var(--primary);
}

.no-scroll {
  overflow: hidden;
}

/* 토스트 알림 */
.Toastify__toast {
  font-size: 12px;
  padding: 12px 24px !important;
  background: var(--black) !important;
  color: #fff !important;
  border-radius: 0 !important;
  margin: 10px 0;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}

.Toastify__toast-container {
  margin-top: 42px;
  width: auto;
  padding: 0;
}

.Toastify__toast-body {
  margin: 0;
  padding: 0;
  height: 16px;
}

.Toastify__close-button {
  color: #fff !important;
  opacity: 1;
  height: 16px;
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.Toastify__toast-icon {
  display: none;
}

.Toastify__progress-bar--success {
  background: #fff;
  opacity: 0.1;
}

/* 노출 영역 설정 */
.toggle_wrap {
  padding: 60px 60px 38px;
}

.toggle_wrap h1 {
  margin-bottom: 6px;
}

.toggle_wrap > .body1 {
  margin-bottom: 32px;
}

.toggle_wrap .d-flex {
  margin-bottom: 22px;
}

.toggle_wrap .d-flex label {
  width: 300px;
}

.toggle-title {
  font-weight: 600;
}

.toggle-title .label1 {
  margin-bottom: 0;
}

.toggle-title .label1:first-child {
  width: 300px;
}
