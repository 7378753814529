.intro_login h1.title2 {
  margin: 0;
  cursor: pointer;
}

.signup_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 280px;
}

.signup_container {
  width: 550px;
  padding: 10px 90px 60px;
  border-radius: 5px;
  border: 1px solid var(--gray5);
  background: #fff;
}

.signup_wrap h1 {
  margin: 50px 0 30px;
}

input:focus-visible {
  outline: none;
}

.signupForm_wrap .btn {
  width: 106px;
  height: 40px;
  padding: 10px 26px;
  border-radius: 5px;
  background: var(--primary);
  color: #fff;
  border: none;
  margin-left: 10px;
}

.signupForm_wrap .btn:hover {
  background: var(--primary-D);
}

.signupForm_wrap .sign_btn {
  width: 360px;
  height: 50px;
  padding: 8px 18px;
  margin-top: 40px;
  border-radius: 5px;
  background: var(--secondary);
  color: #fff;
}

/* 개인정보 입력 */
.form_box {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.form_box .label1 {
  margin-bottom: 10px;
}

.form_box .label1 span,
.consentForm_wrap .consent_cont > p span {
  color: var(--red);
}

.form_box input {
  width: 360px;
  height: 40px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--gray3);
  background: #fff;
}

.form_box .body3 {
  color: var(--gray2);
  margin-top: 6px;
}

.form_box textarea {
  resize: none;
  width: 360px;
  height: 150px !important;
  padding: 5px 0 5px 10px;
  border-radius: 5px;
  border: 1px solid var(--gray4);
  background: #fff;
}

.form_box textarea:focus-visible {
  outline: none;
}

/* 약관동의 */
.consentForm_wrap {
  word-break: keep-all;
}

.consentForm_wrap .consent_cont {
  margin-bottom: 30px;
}

.signup_wrap .consent_cont > p {
  color: var(--black);
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 10px;
}

.signup_wrap .scroll_wrap {
  width: 360px;
  height: 150px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
  background: #fff;
}

.signup_wrap .scroll_box {
  width: 100%;
  height: 130px;
  padding-right: 10px;
  overflow-y: scroll;
  display: inline-block;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.14px;
  color: var(--gray2);
}

.signup_wrap .scroll_box span {
  font-weight: 600;
}

.signup_wrap .scroll_box .body2,
.signup_wrap label.body2 {
  color: var(--gray2);
}

.scroll_box::-webkit-scrollbar {
  width: 5px; /* 스크롤바 너비 */
}

.scroll_box::-webkit-scrollbar-thumb {
  height: 50%; /* 스크롤바 길이 */
  background: var(--gray3); /* 스크롤바 색상 */
  border-radius: 20px;
}

.scroll_box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--gray4); /* 스크롤바 뒷배경 색상 */
}

.consentForm_wrap table {
  width: 320px;
  text-align: center;
  margin: 10px 0;
  font-size: 12px;
  line-height: 16px;
  word-break: keep-all;
}

.consentForm_wrap table thead,
.consentForm_wrap .table_title {
  background: var(--BG);
  font-weight: 700;
}

.consentForm_wrap table tbody {
  vertical-align: middle;
}

.consentForm_wrap th,
.consentForm_wrap td {
  border: 1px solid var(--gray5);
  padding: 4px;
}
