.social_wrap .container {
  height: auto;
  margin-bottom: 100px;
}

.detail_social {
  align-items: flex-start;
  justify-content: space-between;
}

/* 설정 키워드 트렌드 */
.social_wrap {
  width: 1000px;
}

.social_wrap .naver_box {
  width: 30%;
}

/* 연관 키워드 비교 */
.naverkeyword_wrap {
  height: 450px;
}

.network {
  font-weight: 400;
  color: var(--secondary);
}

.related_wrap {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* 순위 키워드 */

.social_wrap .keyword_list .body2 {
  cursor: pointer;
}

.social_wrap .keyword_list .body2:hover {
  color: var(--primary);
}

/* 뉴스 박스 */
.googlekeyword_wrap .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.googlekeyword_wrap .google_box {
  padding: 20px 15px 15px 20px;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.googlekeyword_wrap .google_box a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-bottom: 15px;
}

.googlekeyword_wrap .google_box .body3 {
  padding-top: 20px;
}

.googlekeyword_wrap .google_box .d-end img {
  cursor: pointer;
  padding: 5px;
}
