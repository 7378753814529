/* nav */
.nav_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999999;
}

.nav_container .nav {
  width: 987px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.nav_container .login {
  color: #fff;
  padding: 5px 12px;
  border-radius: 5px;
  background: var(--primary);
  margin-left: 15px;
}

.nav_container .login:hover {
  background: var(--primary-D);
}

.intro_login button {
  padding: 5px 12px;
  border-radius: 5px;
  background: var(--primary);
  color: #fff !important;
}

.intro_login button:hover,
.login:hover {
  background: var(--primary-D);
}

/* nav- dropdown */
.dropdown {
  position: relative;
  display: inline-block;
  height: 24px;
}

.dropdown-content {
  position: absolute;
  top: 30px;
  right: 0px;
  display: none;
  width: 140px;
  border-radius: 5px;
  border: 1px solid var(--gray4);
  background: #fff;
  box-shadow: 3px 4px 0.8px 0px rgba(0, 0, 0, 0.03);
  z-index: -1;
}

.dropdown-content a:hover {
  background-color: var(--gray5);
}

.dropdown-content.visible {
  display: block;
  z-index: 1;
}

.dropdown-content a {
  display: block;
  padding: 14px 16px;
}

#person {
  cursor: pointer;
  margin-left: 15px;
}

/* contents */
.home_container {
  width: 1000px;
  padding-top: 90px;
  margin: 10px auto 250px;
  text-align: center;
}

.home_container .cont_wrap {
  margin-bottom: 35px;
  border-radius: 8px;
  text-align: left;
  position: relative;
}

.arrange_wrap {
  background: rgb(51 51 51 / 50%);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.arrange_wrap::after {
  background-image: url(../assets/arrange_hover.png);
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 36px 50px;
  background-position-x: right;
  background-position-y: 55%;
}

.home_container .cont_wrap .cont_title {
  height: 40px;
  padding: 8px 15px;
}

.home_container .tooltip {
  margin-left: 5px;
}

.home_container .tooltip img {
  width: 16px;
  height: 16px;
}

.home_container .tooltip .tooltip_text {
  width: max-content;
  left: 30px;
}

.home_container .home_box {
  display: inline-flex;
  margin: 0 5px;
}

.sm_global_box .sm_global_wrap {
  background: none;
  width: 60px;
  height: 26px;
  font-size: 0;
}

.sm_global_box .sm_global_wrap .sm_global {
  width: 26px;
  height: 26px;
  padding: 0;
  margin-left: 7px;
}

.sm_global_box .sm_global_wrap .sm_global.selected {
  border: 2px solid var(--primary);
  cursor: pointer;
}

.sm_global_box .sm_global_wrap .sm_global img {
  width: 22px;
  height: 22px;
  margin-right: 0;
}

.home_container .plus_btn {
  padding: 6px 12px;
  border-radius: 2px;
  margin-left: 10px;
}

.homepage .scroll {
  height: 338px;
  padding: 10px 10px 0 10px;
  margin: 10px 8px 10px 10px;
  overflow-y: scroll;
}

.w980 {
  width: 987px;
}

/* 첫번째 줄 */
.NewsSummary {
  width: 326px;
  height: 348px;
}

.maker li::marker {
  content: '- ';
}

/* FieldKeywords */
.FieldKeywords {
  width: 306px;
  height: 358px;
}

.FieldKeywords .wordcloud {
  margin: 10px 0;
}

.FieldKeywords > .d-flex {
  margin: 0 5px;
}

.FieldKeywords .keyword_list {
  width: 50%;
  height: 110px;
  padding: 0 10px;
}

.FieldKeywords .keyword_list .d-between {
  margin-bottom: 6px;
}

.homepage .num {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  color: var(--primary) !important;
  font-weight: 600;
  background: none;
  border: none;
}

.FieldKeywords .keyword_list .body3 {
  width: 72px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FieldKeywords .keyword_list .count {
  width: 28px;
  color: var(--gray3);
  margin-left: 0;
  overflow: visible;
}

.home_keyword_title {
  margin: 0 0 8px !important;
  padding: 0 12px;
}

.home_keyword_title > .keyword_title {
  padding-bottom: 8px !important;
}

.home_keyword_title > .d-between {
  width: 134px;
}

.home_keyword_title p {
  font-weight: 600;
}

/* NewsList */
.NewsList {
  width: 330px;
}

.NewsList.scroll {
  padding: 2px 10px 0 4px;
}

.NewsList .news_card_wrap {
  padding: 12px;
  margin-bottom: 12px;
  margin-right: 1px;
}

.NewsList .news_card_wrap .news_thum > .d-between {
  padding-bottom: 5px;
  align-items: center;
}

.NewsList .news_card_wrap .news_thum .d-flex .label3 {
  font-size: 9px;
}

.NewsList .label4 {
  margin-left: 5px;
}

.NewsList .rating {
  font-size: 11px;
  letter-spacing: 0;
}

.NewsList .news_img {
  width: 75px;
  margin-left: 5px;
}

.NewsList .news_open {
  font-weight: 600;
}

.NewsList .news_related {
  max-height: 240px;
}

.NewsList .news_related .body4 {
  margin-top: 5px;
  padding-top: 5px;
  cursor: pointer;
}

/* 두번째 줄 */
.homepage .StockList {
  width: 327px;
  height: 358px;
}

.homepage .cont_box,
.homepage .StockList .market_cont div {
  padding: 0px;
}

.homepage .StockList .slick-slider {
  width: 260px;
  margin: 20px 0;
}

.homepage .StockList .slick-list {
  width: 260px;
  height: 24px;
}

.homepage .StockList .slide_btn {
  height: 24px;
}

.homepage .StockList .market_cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 292px;
}

.homepage .StockList .market_cont .padding .label3 {
  padding-left: 5px;
}

.homepage .StockList .stock_img img {
  width: 300px;
  height: 150px;
}

.homepage .StockList .market_cont .d-between {
  padding: 0 20px !important;
}

.homepage .StockList .market_cont .padding {
  padding: 12px 0 0 20px;
  line-height: 20px;
}

.homepage .StockList .market_cont .padding p span {
  font-size: 11px !important;
}

.homepage .StockList .market_cont .d-end {
  padding: 28px 16px;
}

.homepage .market_cont .label2 {
  padding: 0;
}

.homepage .market_cont .market_rate {
  margin-top: 0;
  padding-left: 5px;
}

/* 세번째 줄 */
.homepage .Laws {
  width: 320px;
  height: 350px;
}

/* Patent */
.homepage .Laws .scroll {
  width: 300px;
  height: 288px;
}

.homepage .Laws .patents_text {
  width: 270px;
  padding: 5px 0;
}

.homepage .Laws .patents_text .d-flex {
  padding-bottom: 0;
}

.homepage .Laws .scroll > .b-bottom {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray5);
}

.homepage .Laws .patents_text img {
  margin-right: 14px;
}

.homepage .Laws .patents_text .label2 {
  cursor: pointer;
  font-weight: 500;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.homepage .Laws .patents_text .label2:hover {
  font-weight: 600;
}

/* Laws */
.homepage .Laws .text_box {
  padding: 10px 0 5px;
}

.homepage .Laws .patents_text:first-child {
  padding-top: 5px;
}

.homepage .Laws .text_item {
  padding-left: 0;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.homepage .Laws .law_table {
  margin-bottom: 0;
}

.homepage .Laws .law_table thead {
  top: -2px;
}

.homepage .Laws .law_table thead th {
  font-weight: 600;
}

.homepage .laws_wrap .law_table thead tr th:nth-child(1),
.homepage .laws_wrap .law_table tbody tr td:nth-child(1) {
  width: 50px;
  text-align: center;
}

.homepage .laws_wrap .law_table thead tr th:nth-child(2),
.homepage .laws_wrap .law_table tbody tr td:nth-child(2) {
  width: 160px;
  max-width: 160px;
}

.homepage .laws_wrap .law_table tbody tr td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  padding-bottom: 0;
}

.homepage .laws_wrap .law_table thead tr th:nth-child(3),
.homepage .laws_wrap .law_table tbody tr td:nth-child(3) {
  width: 84px;
}

.homepage .laws_wrap .law_table tbody tr td {
  padding: 10px;
  font-weight: 400;
}

.homepage .laws_wrap .law_table tbody tr td.none {
  font-size: 0;
}

.homepage .laws_wrap .law_table tbody tr td img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--gray4);
  font-size: 0;
}

.homepage .Laws.laws_wrap .scroll {
  width: auto;
  padding: 0;
}

.homepage .Laws.laws_wrap .scroll ul {
  padding-left: 10px;
}

/* Legisilation */
.homepage .Laws.legislation .scroll {
  height: 250px;
  padding: 0 10px 0 10px;
}

.homepage .Laws.legislation .d-end .download {
  font-size: 11px;
  margin: 0px 6px 0px 0px;
}

/* Invests */
.homepage .invests_wrap {
  width: 650px;
  height: 350px;
}

.homepage .keyword_title {
  padding-bottom: 5px;
}

.homepage .invests_wrap .keyword_list {
  width: 200px;
  height: 308px;
  /* padding: 14px 20px; */
}

.homepage .invests_wrap .keyword_list .body3 {
  width: 137px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homepage .invests_wrap .table_wrap {
  width: 446px;
  height: 306px;
}

.homepage .invests_wrap .scroll {
  width: 427px;
  height: 262px;
  margin: 0 10px 10px 10px;
  padding: 0;
}

.homepage .invests_wrap .table thead {
  font-size: 12px;
  font-weight: 600;
  border-top: none;
  padding: 0 10px;
}

.homepage .invests_wrap .table thead tr {
  height: 35px;
  display: block;
}

.homepage .invests_wrap .table tbody {
  color: var(--gray1);
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

.homepage .invests_wrap .table thead tr th,
.homepage .invests_wrap .table tbody tr td {
  padding: 10px;
}

.homepage .invests_wrap .table thead tr th {
  padding: 10px;
}

.homepage .invests_wrap .table thead tr th:nth-child(1),
.homepage .invests_wrap .table tbody tr td:nth-child(1) {
  width: 110px;
}

.homepage .invests_wrap .table thead tr th:nth-child(2),
.homepage .invests_wrap .table tbody tr td:nth-child(2) {
  width: 172px;
}

.homepage .invests_wrap .table thead tr th:nth-child(3),
.homepage .invests_wrap .table tbody tr td:nth-child(3) {
  width: 72px;
}

.homepage .invests_wrap .table thead tr th:nth-child(4),
.homepage .invests_wrap .table tbody tr td:nth-child(4) {
  width: 66px;
}

/* Report */
.homepage .report_wrap .scroll {
  width: 300px;
  height: 262px;
  padding: 0;
  margin: 0 8px 10px 10px;
}

.homepage .report_wrap table {
  display: block;
  text-align: left;
}

.homepage .report_wrap table thead tr th {
  height: 35px;
  padding: 10px 12px;
}

.homepage .report_wrap table tbody tr td {
  padding: 8px;
  font-weight: 400;
}

.homepage .report_wrap table thead tr th:nth-child(1) {
  width: 135px;
}

.homepage .report_wrap table tbody tr td:nth-child(1) {
  width: 130px;
}

.homepage .report_wrap table thead tr th:nth-child(2),
.homepage .report_wrap table tbody tr td:nth-child(2) {
  width: 120px;
}

.homepage .report_wrap table thead tr th:nth-child(3) {
  width: 62px;
}

.homepage .report_wrap table tbody tr td:nth-child(3) {
  width: 44px;
}

/* Social */
.homepage .googlekeyword_wrap {
  width: 240px;
  height: 350px;
  border-bottom: none;
}

.homepage .googlekeyword_wrap .keyword_list {
  width: 230px;
  height: 356px;
  margin: 0;
  padding: 16px 36px;
}

.homepage .googlekeyword_wrap .keyword_list .google_img {
  margin-bottom: 20px;
  text-align: center;
}

.homepage .googlekeyword_wrap .keyword_list .google_img img {
  height: 22px;
  margin-bottom: 8px;
}

.homepage .googlekeyword_wrap .num {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  color: var(--primary);
  font-weight: 600;
  background: none;
  border: none;
}

.homepage .googlekeyword_wrap .keyword_list li p.body3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  cursor: pointer;
}

.homepage .naverkeyword_wrap {
  height: 356px;
  padding: 15px;
  text-align: center;
}

.homepage .navertrend_wrap {
  height: 355px;
  padding: 15px;
  text-align: center;
}

.social_wrap .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social_wrap .google_logo {
  margin: 16px 0 10px;
}

.google_logo img {
  height: 22px;
  margin-bottom: 8px;
}

.social_wrap .naver_logo {
  margin: 22px 0 10px;
}

.naver_logo img {
  height: 11px;
  margin: 5px 0 14px;
}

.social_wrap .naver_logo .label3,
.social_wrap .google_logo .label3 {
  margin-top: 11px;
}

/* EventSchedule */
.event_wrap .scroll {
  height: 288px;
}

.event_wrap .scroll ul li {
  line-height: 16px;
}

.event_wrap .scroll .d-between > img {
  margin-left: 5px;
}

.event_wrap .scroll > .d-flex {
  align-items: flex-start;
  margin-bottom: 10px;
}

.event_wrap .scroll > .d-flex .body4 {
  width: 186px;
}

.event_wrap .scroll > .d-flex .body4 p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.event_wrap .news_img {
  width: 80px;
  padding-top: 0;
  margin-right: 12px;
}

.event_wrap .event_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.event_wrap .navy {
  color: var(--secondary);
}

.scroll .gray3 {
  color: var(--gray3);
}

/* techmeme */
.techmeme_wrap {
  width: 650px;
  height: 350px !important;
}

.techmeme_wrap .scroll {
  width: 630px;
  height: 288px;
  padding: 5px 10px 0px 5px;
}

.techmeme_wrap .techmeme_box_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  line-height: 17px;
}

.techmeme_wrap .techmeme_box {
  padding: 15px;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}
