@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css');
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-style: normal;
}

/* 가이드 */
:root {
  /* Theme */
  --primary: #1eb3b8;
  --primary-D: #2c959c;
  --primary2: #5ccba3;
  --primary3: #96e587;
  --primary4: #81dbd1;
  --primary5: #dff1f2;

  --BG: #f8fafb;
  --secondary: #0d1a43;

  /* gary */
  --black: #000;
  --gray1: #333;
  --gray2: #666;
  --gray3: #999;
  --gray4: #dbdbdb;
  --gray5: #efefef;

  /* Basic */
  --yellow: #ffc700;
  --orange: #f59e2d;
  --pink: #ee313b;
  --blue: #2b98ff;
  --red: #ff5757;
}

* {
  font-family: 'Pretendard Variable', 'Noto Sans KR', sans-serif;
}

html {
  position: relative;
  background: var(--BG);
}

strong {
  display: block;
  margin-top: 12px;
}

.line-height {
  line-height: 18px;
}

.box_wrap {
  min-width: 1000px;
  width: 1000px;
  padding-top: 80px;
  margin: 10px auto 0;
}

.d-flex {
  display: flex !important;
  align-items: center;
}

.d-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.d-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inlineFlex {
  display: inline-flex;
}

.inlineFlex > div {
  margin: 0 5px;
}

.keyword_wrap .d-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* 공통 */
.maker li::marker {
  content: '- ';
}

.no-news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  text-align: center;
}

.no-news p {
  margin-top: 18px;
}

.no-news span {
  display: block;
  padding-top: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: var(--primary-D);
}

.container {
  background: #fff;
  border: 1px solid var(--gray5);
  margin-top: 28px;
  border-radius: 8px;
  height: 450px;
}

.sub_title {
  padding: 10px 20px;
}

.sub_title .title2 {
  margin: 0 10px 0 8px;
}

.sub_title .body2 {
  color: var(--gray1);
  padding-top: 2px;
}

.keyword_wrap {
  padding-bottom: 10px;
}

.keyword_wrap .label2 {
  width: 40px;
  min-width: 40px;
  margin-right: 10px;
  color: var(--gray2);
}

/* 로그인 */
.login_wrap {
  width: 100vw;
  height: 100vh;
  position: relative;
  text-align: center;
}

.login_container {
  width: 348px;
  padding: 35px 50px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  border: 1px solid var(--gray5);
  background: #fff;
}

.login_wrap .column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_wrap .title1 {
  padding-bottom: 26px;
  color: var(--secondary);
}

.login_wrap input {
  width: 244px;
  height: 36px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
  background: #fff;
  margin-bottom: 10px;
}

.login_wrap input:focus-visible {
  outline: none;
}

.login_wrap .login {
  width: 244px;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  background: var(--primary);
  color: #fff;
  margin-top: 18px;
  margin-bottom: 20px;
}

.login_wrap .login:hover {
  background: var(--primary-D);
}

.login_wrap .find_wrap {
  color: var(--gray2);
  padding: 12px 0 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid var(--gray5);
}

.login_wrap .link a {
  height: 32px;
}

.login_wrap .link a img {
  width: 20px;
}

.login_wrap .link .d-start {
  border-radius: 5px;
  background: var(--gray5);
  margin-bottom: 8px;
  color: #000;
  padding-left: 60px;
  cursor: pointer;
}

.login_wrap .link .d-start p {
  padding: 8px;
  margin-left: 10px;
}

.login_input > label {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.login_input > label > .d-flex {
  margin: 0 10px 10px 0;
}

.login_input input.login_set_input {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 6px 0 0;
  border-radius: 50%;
  cursor: pointer;
}

.login_input [type='radio'] {
  appearance: none;
  box-shadow: 0 0 0 1px var(--gray3);
  border: 3px solid #fff;
  background-color: #fff;
}

.login_input [type='radio']:checked {
  box-shadow: 0 0 0 1px var(--primary);
  border: 3px solid #fff;
  background-color: var(--primary);
}

.checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox input {
  position: relative;
  width: 18px;
  height: 18px;
  padding: 0;
  border: 1px solid var(--gray3);
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 0 8px 0 0;
}

.checkbox input::before {
  content: '\2713';
  width: 18px;
  height: 18px;
  border: 1px solid var(--primary);
  border-radius: 3px;
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.checkbox input:checked::before {
  opacity: 1;
}

/* find password */
.find_wrap .find_cont {
  border-bottom: 1px solid var(--gray5);
}

.find_wrap .sub_tit {
  text-align: left;
  padding-bottom: 10px;
}

.find_wrap .login {
  margin: 10px 0 18px;
}

.find_wrap input {
  font-size: 12px;
}

.find_wrap .link {
  margin-top: 18px;
}

/* login - footer */
.footer_wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px 0 30px;
}

.footer_container {
  width: 1000px;
  margin: 0 auto;
}

.footer_wrap img {
  margin-bottom: 16px;
}

.footer_wrap .d-flex p {
  font-weight: 600;
  color: var(--gray2);
  margin: 0 12px 6px 0;
}

.footer_container .body3 {
  padding-bottom: 4px;
  color: var(--gray3);
}

.footer_wrap .copy {
  color: var(--gray3);
  margin-top: 8px;
  padding-bottom: 0;
}

/* 주식 - 전일대비 */
.up {
  color: var(--red) !important;
}

.same {
  color: var(--gray2) !important;
}

.down {
  color: var(--blue) !important;
}

/* slide */
.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-list {
  width: 400px;
  height: 26px;
}

.slick-track {
  height: 27px;
  margin-left: unset !important;
}

.stock_box .slick-track {
  width: auto !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.slick-slide {
  width: auto !important;
  margin-right: 4px;
}

.slick-slider {
  width: 400px;
}

.slick-prev::before {
  content: url('../assets/prev_btn_slide.svg') !important;
  filter: invert(100%);
}

.slick-next::before {
  content: url('../assets/next_btn_slide.svg') !important;
  filter: invert(100%);
}

/* 버튼 */
.disabled {
  background: var(--gray5) !important;
  color: var(--gray3) !important;
  cursor: default;
}

.keyword {
  padding: 2px 8px;
  margin: 3px;
  border-radius: 3px;
  background: var(--gray5);
  color: var(--gray2) !important;
}

.global_btn_wrap {
  width: 148px;
  height: 32px;
  border-radius: 20px;
  background: var(--gray5);
}

.global_btn {
  position: relative;
  width: 72px;
  height: 28px;
  padding: 6px 12px;
  border-radius: 20px;
  color: var(--gray2) !important;
  border: none;
  cursor: pointer;
}

.global_btn.selected {
  background: #fff;
  color: var(--black) !important;
  cursor: default;
}

.global_btn img {
  width: 20px;
  margin-right: 5px;
}

.btn {
  padding: 5px 15px;
  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
}

.btn.label2 {
  color: var(--gray3);
  font-weight: 500;
}

.btn.label2.selected {
  border: 1px solid var(--primary);
  background: var(--primary);
  color: #fff !important;
}

.download {
  padding: 8px 18px;
  margin-left: 5px;
  border-radius: 5px;
  background: var(--primary);
  color: #fff;
  font-size: 12px;
}

.download:hover {
  background: var(--primary-D);
}

.download img {
  margin-right: 5px;
}

.slide_btn {
  width: 100%;
  height: 26px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
  color: var(--gray3, var(--gray3)) !important;
  display: flex;
  padding: 5px 15px;
  font-weight: 500 !important;
}

.slide_btn.selected,
.wordcloud_container > .selected {
  border: 1px solid var(--primary);
  background: var(--primary);
  color: #fff !important;
}

/* 스크롤 */
.scroll {
  overflow-y: scroll;
  height: 357px;
  margin: 10px;
}

.scroll::-webkit-scrollbar,
.news_related::-webkit-scrollbar {
  width: 6px;
}

.scroll::-webkit-scrollbar-thumb,
.news_related::-webkit-scrollbar-thumb {
  background: var(--gray4);
  border-radius: 4px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.scroll::-webkit-scrollbar-track,
.news_related::-webkit-scrollbar-track {
  border-radius: 4px;
}
/* 툴팁 */
.tooltip_one .tooltip .tooltip_text {
  top: -5px;
}

.tooltip_one .tooltip .tooltip_text::before {
  top: 15px;
}

.tooltip {
  position: relative;
  display: flex;
  cursor: pointer;
  margin-left: 10px;
}

.tooltip .tooltip_text {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: 40px;
  z-index: 2;
  transition: opacity 0.2s;
  width: max-content;
  background: var(--secondary);
  border-radius: 5px;
  padding: 10px;
  color: #fff;
}

.tooltip .tooltip_text_news {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 25px;
  left: -8px;
  z-index: 1;
  transition: opacity 0.2s;
  width: 195px;
  background: var(--secondary);
  border-radius: 5px;
  padding: 10px;
  color: #fff;
}

.tooltip:hover .tooltip_text,
.tooltip:hover .tooltip_text_news {
  opacity: 1;
  visibility: visible;
}

.home_container .tooltip .tooltip_text,
.tooltip .tooltip_text_news {
  color: var(--gray5);
  line-height: 17px;
}

.home_container .tooltip .tooltip_text p .primary {
  color: var(--primary4) !important;
}

.tooltip .tooltip_text::before,
.tooltip .tooltip_text_news::before {
  content: '';
  position: absolute;
  top: 30px;
  left: -12px;
  margin-top: -5px;
  transform: rotateY(180deg);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent var(--secondary);
}
.tooltip .tooltip_text_news::before {
  top: -7px;
  left: 30px;
  margin-top: -5px;
  transform: rotate(270deg);
}

/* 나라 tooltip */
.tooltip_box {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -54px;
  left: 58%;
  transform: translateX(-50%);
  z-index: 2;
  transition: opacity 0.2s;
  width: 136px;
  background: var(--secondary);
  border-radius: 5px;
  padding: 8px 10px;
}

.tooltip_box .tooltip_text {
  color: #fff;
}

.global_btn:hover .tooltip_box,
.Ai_recommend:hover .tooltip_box {
  opacity: 1;
  visibility: visible;
}

.tooltip_box .tooltip_text::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  transform: rotate(360deg);
  border-width: 6px;
  border-style: solid;
  border-color: var(--secondary) transparent transparent transparent;
}

.Ai_recommend .tooltip_box .tooltip_text::before {
  top: 30%;
  left: 0%;
  transform: rotate(90deg);
}

.Ai_recommend .tooltip_box {
  width: 360px;
}

.tooltip_k .tooltip_box {
  top: -56px;
  left: 68%;
}

.tooltip_ai .tooltip_box {
  top: 0px;
  left: 62%;
}

h1 {
  font-size: 24px;
  font-weight: 600 !important;
  letter-spacing: -0.48px;
}

.title1 {
  font-size: 20px;
  font-weight: 700;
}

.title2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--secondary);
}

.title3 {
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary);
}

.label1 {
  font-size: 15px;
  font-weight: 600;
  color: var(--black);
}

.label2 {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
}

.label3 {
  font-size: 12px;
  font-weight: 700;
  color: var(--black);
}

.label4 {
  font-size: 11px;
  font-weight: 600;
  color: var(--black);
}

.body1 {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}

.body2 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  color: var(--black);
}

.body3 {
  font-size: 12px;
  font-weight: 400;
  color: var(--black);
}

.body4 {
  font-size: 11px;
  font-weight: 400;
  color: var(--black);
}

.highlight {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.primary {
  color: var(--primary) !important;
  font-weight: 600;
}

.primary-D {
  color: var(--primary-D);
}

.gray2 {
  color: var(--gray2);
}

.gray3 {
  color: var(--gray3) !important;
}

.span {
  color: var(--primary);
  padding-right: 8px;
}

.stock_box .cont2 span {
  font-weight: 600;
  padding-left: 8px;
}

.error_text {
  width: 100%;
  color: var(--pink);
  text-align: left;
  margin-bottom: 10px;
}

/* 커밍순 */
.comingsoon {
  width: 100%;
  height: 89%;
  position: absolute !important;
  border-radius: 0 0 6px 6px !important;
  background-image: url(../assets/comingsoon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-color: rgb(255 255 255 / 80%);
  margin-top: -10px;
  z-index: 1;
}

.lawQna_container .comingsoon {
  margin-top: -1px;
  border-top: 1px solid var(--gray5);
}
