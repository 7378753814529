/* 소개 페이지 */
.intro_wrap {
  width: 1000px;
  margin: 0 auto 335px;
}

.intro_wrap .section {
  height: 400px;
  border-radius: 20px;
  margin-top: 80px;
  overflow: hidden;
}

.intro_wrap .text_box {
  margin-left: 56px;
}

.intro_wrap h1 {
  color: var(--black);
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
}

.intro_wrap p {
  margin-top: 20px;
  color: var(--gray2);
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

/* section1 */
.section1 {
  padding-left: 10px;
}

.section1 h1 {
  color: var(--secondary);
  font-size: 35px;
  font-weight: 600;
  line-height: 47px;
}

.intro_wrap strong {
  display: inline-block;
  margin-top: 0;
}

.section1 h1 span {
  color: var(--primary);
}

.section1 p {
  color: var(--secondary);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin: 6px 0 35px 0;
}

.intro_wrap button {
  width: 218px;
  height: 45px;
  border-radius: 4px;
  background: var(--secondary);
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 8px;
  margin-left: 10px;
  margin-bottom: 5px;
}

/* section2 */
.section2,
.section4 {
  background: #f0f6f9;
}

.section2 img {
  margin-right: 135px;
}

/* section3 */
.section3,
.section5 {
  background: #f4f8f2;
}

.section3 img {
  margin-top: 74px;
}

/* section4 */
.section4 img {
  margin-right: 24px;
}

/* section5 */
.section5 {
  height: 500px;
  border-radius: 20px;
  margin-top: 40px;
  overflow: hidden;
}

.section5 .d-between {
  margin: 48px 65px 36px;
}

.section5 p {
  margin-top: 0;
}
