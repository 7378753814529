.dash_wrap .d-between {
  align-items: flex-start;
}

.dash_wrap .btn {
  margin: 0 0 0 10px;
}

.dash_wrap {
  max-width: 1920px;
  margin: 0 auto;
}

.dash_wrap .label2 {
  font-size: 13px;
  color: #000;
}

.dash_wrap .nav_container {
  border-bottom: 1px solid #dbdbdb;
}

.dash_wrap .nav_container .nav {
  width: 1620px;
  height: 70px;
}

.dash_wrap .nav_container .nav h1 {
  font-size: 22px;
  font-weight: 700 !important;
}

.dash_wrap .nav {
  position: relative;
}

.dash_wrap .dash_container {
  width: 1620px;
  margin: 70px auto 60px;
}

/* 확대 축소 버튼 */
.zoom-container {
  transition: transform 0.2s ease-in-out;
}

.area-zoom-btn {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}

.area-zoom-btn .zoom-btn .zoom,
.area-zoom-btn .zoom-btn .zoomout {
  width: 30px;
  height: 30px;
  background-color: rgb(217 217 217 / 70%);
  border-radius: 10px;
  margin-left: 10px;
  color: #0d1a43;
  font-size: 24px;
  line-height: 24px;
}

/* 오늘의 뉴스 */
.dash_wrap .dash-data {
  padding: 36px 0;
  font-size: 20px;
  font-weight: 400;
}

.dash_wrap .dash-data p {
  padding-left: 20px;
  font-weight: 700;
}

.dash_box_wrap .dash_box {
  width: 800px;
  padding: 24px 34px;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
}

.dash_box_wrap .dash_box.box1 {
  height: 854px;
}

.dash_box_wrap .dash_box.box2 {
  height: 260px;
  padding: 24px 34px 34px 40px;
}

.dash_box_wrap .dash_box.box3 {
  height: 265px;
  margin: 20px 0;
  padding: 24px 40px 34px;
}

.dash_box_wrap .dash_box.box4 {
  height: 289px;
  padding: 24px 40px 34px;
}

.dash_box_wrap .dash_box h2 {
  font-size: 26px;
  font-weight: 700;
  color: #0d1a43;
  padding-left: 10px;
}

.dash_box_wrap .keyword_wrap ul {
  margin: 34px 0 26px;
}

.dash_box_wrap .keyword_wrap ul .keyword {
  padding: 5px 20px;
  font-size: 18px;
  color: #666;
  cursor: pointer;
}

.dash_news_cont {
  position: relative;
  padding: 12px 38px;
  margin-bottom: 6px;
  border: 1px solid #efefef;
  background: #f8fafb;
  border-radius: 8px;
}

.dash_news_cont .img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #999;
}

.dash_news_cont .media {
  width: 140px;
  padding: 0 16px 0 8px;
  font-size: 16px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.news4 {
  margin-top: 10px;
  width: 120px;
  height: 120px;
  border-radius: 5px;
}

.dash_news_cont .title {
  width: 520px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dash_news_cont.d-flex {
  align-items: flex-end;
}

.dash_news_cont.summary-on {
  background-color: #fff;
  border: none;
  padding-top: 18px;
  padding-bottom: 24px;
}

.dash_news_cont.summary-on .img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #999;
}

.dash_news_cont.summary-on .media {
  font-size: 16px;
  font-weight: 500;
}

.dash_news_cont.summary-on .title {
  font-size: 20px;
  font-weight: 700;
}

.dash_news_cont .reason {
  margin: 12px 0 10px;
  background-color: #81dbd1;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
}

.dash_news_cont .reason span {
  font-weight: 700;
}

.dash_news_cont .reason .news_icon {
  display: inline-block;
  font-size: 0;
}

.dash_news_cont .summary {
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 6px;
}

.dash_container .wordcloud {
  border-top: 1px solid #dbdbdb;
}

/* 오늘의 증시현황 */
.dash_stock_wrap.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;
}

.table_wrap_dash table tr th,
.table_wrap_dash table tr td {
  padding: 6px 18px;
  height: 28px;
  border: 1px solid #efefef;
  text-align: center;
}

.table_wrap_dash table tr th {
  background: #f8fafb;
}

.table_wrap_dash table tr.highlight {
  background: #f0fbfc !important;
}

.table_wrap_dash .company_name p {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.stock_img img {
  height: 130px;
  transition: transform 0.5s ease-in-out;
}

/* 규제, 특허 */
.btn.active {
  border: 1px solid var(--primary);
  background: var(--primary);
  color: #fff !important;
}

.dash_legi_wrap {
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  margin-top: 24px;
  padding: 18px 38px 25px;
  /* 임시 */
  height: 150px;
  overflow: hidden;
}

.dash_legi_wrap strong {
  font-size: 20px;
  margin-top: 0;
}

.dash_legi_wrap li,
.dash_legi_wrap li p {
  font-size: 18px;
  line-height: 24px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dash_legi_wrap li {
  /* 임시 */
  padding-bottom: 30px;
}

.dash_patent_wrap .country {
  margin-right: 10px;
}

.dash_patent_wrap li {
  padding-top: 0;
}

.dash_patent_wrap li p {
  width: 610px;
  font-weight: 500;
  padding-top: 0;
}

.dash_patent_wrap .slide {
  margin-top: 20px;
}

.dash_patent_wrap .slide-indicator {
  color: #666;
}

.dash_patent_wrap .slide-indicator span {
  color: #1eb3b8;
  font-size: 18px;
  font-weight: 600;
}

/* 소셜 트렌드 */
.dash_social_wrap {
  height: 40px;
  padding: 12px 38px;
  margin-top: 16px;
  background-color: #f0fbfc;
  border-radius: 8px;
  border: 1px solid #efefef;
}

.dash_social_wrap .trend_title,
.dash_trend_wrap .trend_title {
  width: 136px;
  min-width: 136px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 50px;
}

.dash_social_wrap .trend_title img,
.dash_trend_wrap .trend_title img {
  margin-right: 8px;
}

.dash_social_wrap .trend_num {
  font-size: 18px;
  font-weight: 500;
  color: #1eb3b8;
  margin-right: 10px;
}

.dash_social_wrap .trend_data {
  font-size: 16px;
  font-weight: 600;
}

.dash_trend_wrap {
  align-items: flex-start;
  padding: 12px 38px;
  height: 65px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #dbdbdb;
}

.google_box:first-child {
  margin-bottom: 8px;
}

.google_box p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.google_box .body2:nth-child(1) {
  min-width: fit-content;
  color: #333;
  padding-right: 16px;
}

.google_box .body2:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
