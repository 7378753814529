/* 상세페이지 공통 */
.menu_wrap {
  width: 100%;
  height: 52px;
  background: #fff;
}

.menu_wrap > .d-between {
  width: 1000px;
  margin: 0 auto;
  padding: 0 10px;
}

.menu_wrap .title2 {
  margin-right: 10px;
}

.menu_wrap .title3 {
  position: relative;
  padding: 10px;
  margin-left: 10px;
  font-weight: 600;
}

.menu_wrap .title3:hover {
  color: var(--primary);
  font-weight: 700;
}

.menu_wrap .title3::after {
  position: absolute;
  content: '';
  display: block;
  border-bottom: 2px solid var(--primary);
  transition: all 0.1s ease-out;
  width: 0%;
  left: 50%;
  bottom: -21%;
}

.menu_wrap .title3:hover::after {
  transition: all 0.1s ease-out;
  width: 100%;
  left: 0%;
}

.main_title {
  min-width: 1000px;
  font-size: 34px;
  font-weight: 800 !important;
  line-height: 70px;
  text-transform: capitalize;
  color: var(--secondary);
  text-align: center;
  margin: 90px 0;
}

.dashboard h1 {
  font-weight: 600;
}

/* dashboard - box_wrap */
.box {
  width: 1000px;
  margin-bottom: 80px;
  transition: all 0.4s;
}

.box:hover {
  transform: scale(1.02);
  z-index: 9999;
}
