/* 법규 정보, 규제 정보, 특허 정보, 판례 정보 */
.laws_wrap .keyword_wrap .d-grid {
  display: flex;
  align-items: center;
  margin: 0;
}

.laws_container {
  padding-left: 20px;
}

.laws_container .scroll {
  height: 426px;
  margin: 10px 10px 0 0;
  padding: 10px 20px 0 0;
}

.laws_container .keyword_wrap {
  padding-bottom: 18px;
}

.laws_contents_wrap .label1 {
  font-weight: 700;
}

.laws_contents_wrap .body2 {
  padding-left: 8px;
}

.text_box {
  padding: 20px 0 10px;
}

.text_item {
  padding-left: 5px;
  cursor: pointer;
}

.text_item:hover {
  color: var(--primary);
}

/* 해외 법규 테이블 */
.law_table {
  text-align: left;
  margin-bottom: 10px;
}

.law_table thead {
  position: sticky;
  top: -1px;
  height: 35px;
  font-weight: 600;
}

.law_table thead tr {
  padding: 0 32px;
}

.law_table thead tr th {
  padding: 10px;
}

.law_table tbody tr {
  cursor: pointer;
}

.law_table tbody tr td {
  width: 150px;
  padding: 15px 10px;
}

.law_table tbody tr td:nth-child(2) {
  width: 640px;
}

.law_table .title3 img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1px solid var(--gray5);
}

.law_table .title3.center {
  display: flex;
  align-items: center;
}

/* 규제 정보 */
.legislation_wrap .scroll {
  height: 300px;
}

.legislation_wrap .label1 {
  color: var(--secondary);
  padding-bottom: 15px;
}

.legislation_wrap .label3 {
  color: var(--gray2);
  font-weight: 600;
  position: relative;
  margin-right: 10px;
}

.legislation_wrap .label3::before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: -5px;
  width: 100px;
  height: 1px;
  background: var(--gray3);
}

.legislation_wrap .d-end {
  padding: 20px;
}

/* 특허 정보 */
.laws_container .patents_wrap {
  cursor: pointer;
  padding: 0 20px 10px;
  margin-bottom: 10px;
}

.laws_container .patents_wrap:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.patents_img {
  width: 150px;
  height: 150px;
}

.patents_img img {
  max-width: 100%;
  max-height: 100%;
}

.patents_text {
  width: 680px;
  padding-right: 10px;
}

.patents_text .d-flex {
  align-items: flex-start;
  padding-bottom: 10px;
}

.patents_text .label2 {
  padding: 10px 0 5px;
}

.patents_text img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid var(--gray5);
}
