.react-chatbot-kit-chat-header,
.options-header,
.react-chatbot-kit-chat-btn-send svg,
.react-chatbot-kit-user-avatar {
  display: none;
}

.react-chatbot-kit-chat-bot-message span,
.react-chatbot-kit-user-chat-message,
.option-item {
  font-size: 13px;
  line-height: 24px;
}

.app-chatbot-container {
  width: 400px;
  height: auto;
  position: fixed;
  right: 30px;
  bottom: 100px;
  padding: 28px 0 28px 32px;
  z-index: 9999;
  background: var(--BG);
  border-radius: 15px;
  box-shadow: 5px 5px 10px var(--gray4);
}

.app-chatbot-container input {
  position: relative;
  width: 100%;
  height: 46px;
  margin: 10px 32px 0 0;
  padding: 12px 14px;
  border: 1px solid #fff;
  border-radius: 10px;
}

.react-chatbot-kit-chat-btn-send {
  width: 46px;
  height: 46px;
  background-image: url(../assets/send.png);
  background-repeat: no-repeat;
  background-color: transparent !important;
  background-position: 15px 15px;
  position: absolute;
  right: 32px;
  bottom: 28px;
}

.react-chatbot-kit-user-chat-message-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0;
}

.react-chatbot-kit-user-chat-message {
  position: relative;
  display: flex;
  background-color: var(--primary5) !important;
  margin: 10px 0;
  padding: 6px 12px;
  border-radius: 10px;
}

.react-chatbot-kit-chat-bot-message {
  position: relative;
  height: fit-content;
  background-color: #fff !important;
  padding: 6px 12px;
  border-radius: 10px;
  margin-left: 10px;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.option-item {
  padding: 6px 12px;
  margin: 3px 0;
  border: 1px solid var(--gray4);
  border-radius: 10px;
  background: var(--primary5);
  color: var(--gray1);
  cursor: pointer;
  width: fit-content;
}

.option-item:hover {
  background: var(--primary5);
  border: 1px solid var(--primary5);
}

.react-chatbot-kit-chat-input-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-chatbot-kit-chat-bot-message span {
  white-space: pre-line;
}

.react-chatbot-kit-chat-bot-avatar-container,
.react-chatbot-kit-chat-bot-avatar-container img {
  width: 36px !important;
  height: 36px !important;
}

.react-chatbot-kit-chat-message-container {
  overflow-y: scroll;
  max-height: 442px;
  padding-right: 32px;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  width: 6px;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb {
  background: var(--gray4);
  border-radius: 4px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
  margin: 10px;
}

/* 챗 버튼 */
.app-chatbot-button {
  width: 56px;
  height: 56px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  border-radius: 20px;
  z-index: 99999;
  opacity: 1;
}

.app-chatbot-button img {
  border-radius: 15px;
}

.app-chatbot-button .chatbot-button-text {
  opacity: 0;
}

.app-chatbot-button:hover .chatbot-button-text {
  width: 130px;
  height: 56px;
  padding: 12px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  line-height: 34px;
  border-radius: 15px 0px 0px 15px;
  background: #fff;
  box-shadow: 0px 1px 10px 0px #00000026;
  position: absolute;
  top: 0;
  right: -86px;
  animation: slideInLeft 0.5s ease-in-out forwards;
  opacity: 1;
  z-index: -99;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  100% {
    transform: translateX(-100%);
    opacity: 1;
  }
}

/* 홈 버튼 */
.home-btn-container,
.arrange-container {
  width: 66px;
  height: 66px;
  position: fixed;
  right: 25px;
  bottom: 165px;
  z-index: 999;
  border-radius: 20px;
  opacity: 1;
}

.home-btn-container a {
  display: block;
  width: 66px;
  height: 66px;
}

.home-btn-container .home-button-text,
.arrange-container .arrange-button-text {
  opacity: 0;
}

.home-btn-container:hover .home-button-text,
.arrange-container:hover .arrange-button-text {
  width: 130px;
  height: 56px;
  padding: 12px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  line-height: 34px;
  border-radius: 15px 0px 0px 15px;
  background: #fff;
  box-shadow: 0px 1px 10px 0px #00000026;
  position: absolute;
  top: 5px;
  right: -82px;
  animation: slideInLeft 0.5s ease-in-out forwards;
  opacity: 1;
  z-index: -99;
}

/* 다크모드 */
.darkmode-btn-container {
  width: 56px;
  height: 56px;
  position: fixed;
  right: 30px;
  bottom: 100px;
  z-index: 999;
  border-radius: 10px;
  opacity: 1;
}

.darkmode-btn-container .darkmode-button-text {
  opacity: 0;
}

.darkmode-btn-container:hover .darkmode-button-text {
  width: 130px;
  height: 56px;
  padding: 12px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  line-height: 34px;
  border-radius: 15px 0px 0px 15px;
  background: #fff;
  box-shadow: 0px 1px 10px 0px #00000026;
  position: absolute;
  top: 0;
  right: -86px;
  animation: slideInLeft 0.5s ease-in-out forwards;
  opacity: 1;
  z-index: -99;
}
