/* 경제 뉴스 주요 키워드, 국내 투자 정보 */
.wordcloud_container {
  padding: 15px 20px;
}

.fieldKeywords_wrap table tbody tr td.body2 {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* 키워드 순위 */
.keyword_list {
  width: 300px;
  padding: 14px 20px;
}

.keyword_list_title {
  padding-bottom: 13px;
}

.keyword_list_title p:first-child {
  padding-right: 10px;
}

.keyword_list p.body2 {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.keyword_list p.count {
  color: var(--gray2);
  width: 30px;
  margin: 0 2px 0 10px;
  text-align: right;
  overflow: visible;
}

.num {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  background: var(--gray5);
  color: var(--gray3, var(--gray3));
  margin: 0 10px 0 2px;
}

.num.best {
  border: 1px solid var(--primary);
  background: var(--primary);
  color: #fff !important;
}

/* 국내 투자 정보 */
.invests_wrap .table thead {
  position: sticky;
  top: -1px;
  height: 35px;
  font-weight: 600;
  display: block;
  text-align: left;
}

.invests_wrap .table thead tr th {
  height: 35px;
  padding: 10px;
}

.invests_wrap .table thead tr th:nth-child(1),
.invests_wrap .table tbody tr td:nth-child(1) {
  width: 88px;
}

.invests_wrap .table thead tr th:nth-child(2),
.invests_wrap .table tbody tr td:nth-child(2) {
  width: 92px;
}

.invests_wrap .table thead tr th:nth-child(3),
.invests_wrap .table tbody tr td:nth-child(3) {
  width: 178px;
}

.invests_wrap .table thead tr th:nth-child(4),
.invests_wrap .table tbody tr td:nth-child(4) {
  width: 92px;
}

.invests_wrap .table thead tr th:nth-child(5),
.invests_wrap .table tbody tr td:nth-child(5) {
  width: 76px;
}

.invests_wrap .table thead tr th:nth-child(6),
.invests_wrap .table tbody tr td:nth-child(6) {
  width: 72px;
}

.invests_wrap .table thead tr th:nth-child(7),
.invests_wrap .table tbody tr td:nth-child(7) {
  width: 78px;
}

.invests_wrap .table tbody tr {
  height: 36.5px;
}

.invests_wrap .table tbody tr td {
  padding: 10px;
}

.table_wrap .d-end {
  padding: 20px 20px 0;
}

.invests_wrap .scroll {
  width: 687px;
  height: 243px;
  padding: 0;
}

.invests_wrap .scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 4px;
}

.invests_slider .slider-container {
  margin: 20px 0;
}

.invests_slider .slick-slider,
.invests_slider .slick-list {
  width: 640px;
  height: 27px;
}
